<div class="row jsutify-content-center">
  <div class="col-12">
    <div class="kt-portlet setting-data kt-portlet--height-fluid">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title card-title">
            {{ "connect.doctor_settings.notification_settings" | translate }}
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>
              {{
                "connect.doctor_settings.appointment_notifications" | translate
              }}
            </h3>
            <p>
              {{
                "connect.doctor_settings.receive_appointment_notifications"
                  | translate
              }}
            </p>
          </div>
          <div class="setting-bx btn-toggles-right">
            <mat-button-toggle-group
              #group="matButtonToggleGroup"
              class="buttons-toggle group-toggle-btn"
              [value]="doctorSettings.bookings"
              (change)="onValChange($event.value)"
            >
              <mat-button-toggle value="sms">{{
                "connect.doctor_settings.sms" | translate
              }}</mat-button-toggle>
              <mat-button-toggle value="email">{{
                "connect.doctor_settings.email" | translate
              }}</mat-button-toggle>
              <mat-button-toggle value="both">{{
                "connect.doctor_settings.both" | translate
              }}</mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="kt-portlet setting-data kt-portlet--height-fluid">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title card-title">
            {{ "connect.doctor_settings.calendar_settings" | translate }}
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>
              {{ "connect.doctor_settings.configure_my_calendar" | translate }}
            </h3>
            <p>
              {{
                "connect.doctor_settings.select_consultation_hours" | translate
              }}
            </p>
          </div>
          <div class="setting-bx">
            <button
              color="primary"
              (click)="navigateUrl('booking-availabilities/new')"
              class="btn btn-success btn-elevate btn-pill setting-btn"
            >
              {{ "connect.doctor_settings.configure_my_calendar" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="kt-portlet__body border-top">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>
              {{
                "connect.doctor_settings.synchronization_with_google_calendar"
                  | translate
              }}
            </h3>
            <p>
              {{
                "connect.doctor_settings.synchronization_with_dabadoc_calendar"
                  | translate
              }}
            </p>
          </div>
          <div>
            <div              
            class="setting-bx"
            >
            <a
            *ngIf="!googleToken"
            href="{{domaineName + '/auth/google_oauth2' }}"
            class="btn btn-info tn-elevate btn-pill setting-btn"
          >
            <i class="fab fa-google-plus-g"></i
            >{{ "connect.doctor_settings.log_in_with_google" | translate }}</a
          >
            </div>
            <div *ngIf="googleToken">
              <mat-slide-toggle [(ngModel)]="doctorSettings.calendar_sync" (change)="onChangeEvent('calendar_sync', $event)"></mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>

      <div class="kt-portlet__body border-top">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>
              {{ "connect.doctor_settings.video_consultation" | translate }}
            </h3>
            <p>
              {{
                "connect.doctor_settings.activate_the_video_consultation_functionalities"
                  | translate
              }}
              <a
                href="https://info.dabadoc.com/asset/55:cgu-consultation-videopdf"
                target="_blank"
                download
                >{{
                  "connect.doctor_settings.general_conditions" | translate
                }}</a
              >
            </p>
          </div>
          <div>
            <mat-slide-toggle [(ngModel)]="doctorSettings.video_consultation" (change)="onChangeEvent('video_consultation', $event)"></mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="kt-portlet__body border-top" *ngIf="!current_doctor?.clinic">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>
              {{ "connect.doctor_settings.home_consultation" | translate }}
            </h3>
            <p>
              {{
                "connect.doctor_settings.activate_the_home_consultation_functionalities"
                  | translate
              }}
            </p>
          </div>
          <div>
            <mat-slide-toggle [(ngModel)]="doctorSettings.home_consultation" (change)="onChangeEvent('home_consultation', $event)"></mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="kt-portlet__body border-top">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>
              {{
                "connect.doctor_settings.minimum_schedule_notice" | translate
              }}
            </h3>
            <p>
              {{
                "connect.doctor_settings.select_minimum_time_interval"
                  | translate
              }}
            </p>
          </div>

          <div>
            <mat-form-field>
              <mat-select
                class="full-width"
                value="{{ selected_minimum_schedule_notice }}"
                placeholder="{{
                  'connect.doctor_settings.select_notice' | translate
                }}"
                (selectionChange)="
                  onChangeEvent('minimum_schedule_notice', $event.value)
                "
              >
                <mat-option *ngFor="let type of types" value="{{ type.value }}">
                  <span *ngIf="type.time_type === 'hours'">
                    {{ type.value }}
                    {{ "connect.doctor_settings.hours" | translate }}
                  </span>
                  <span *ngIf="type.time_type === 'minutes'">
                    {{ type.value * 60 }}
                    {{ "connect.doctor_settings.minutes" | translate }}
                  </span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="kt-portlet__body border-top">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>
              {{
                "connect.doctor_settings.minimum_schedule_notice_vc" | translate
              }}
            </h3>
            <p>
              {{
                "connect.doctor_settings.select_minimum_time_interval_for_videos"
                  | translate
              }}
            </p>
          </div>

          <div>
            <mat-form-field>
              <mat-select
                class="full-width"
                value="{{ selected_minimum_schedule_notice_vc }}"
                placeholder="{{
                  'connect.doctor_settings.select_notice' | translate
                }}"
                (selectionChange)="
                  onChangeEvent('minimum_schedule_notice_vc', $event.value)
                "
              >
                <mat-option *ngFor="let type of types" value="{{ type.value }}">
                  <span *ngIf="type.time_type === 'hours'">
                    {{ type.value }}
                    {{ "connect.doctor_settings.hours" | translate }}
                  </span>
                  <span *ngIf="type.time_type === 'minutes'">
                    {{ type.value * 60 }}
                    {{ "connect.doctor_settings.minutes" | translate }}
                  </span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="kt-portlet setting-data kt-portlet--height-fluid">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title card-title">
            {{ "connect.doctor_settings.other_parameters" | translate }}
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>
              {{
                "connect.doctor_settings.preferred_payment_method" | translate
              }}
            </h3>
            <p>
              {{
                "connect.doctor_settings.choose_preferred_payment_method"
                  | translate
              }}
            </p>
            <div
              class="bg-light p-3 rounded"
              *ngIf="doctorSettings.preferred_payment_method == 'fatourati'"
            >
              <div class="small mb-0">
                <p>
                  {{
                    "connect.doctor_settings.fatourati_payment_method"
                      | translate
                  }}
                </p>
                <ul>
                  <li><p>Attijariwafa</p></li>
                </ul>
                <br />
                <p>
                  <b>{{
                    "connect.doctor_settings.fatourati_payment_method_2nd_part"
                      | translate
                  }}</b>
                </p>
              </div>
            </div>
          </div>

          <div>
            <mat-form-field appearance="outline">
              <mat-select
                class="full-width"
                value="{{ selected }}"
                placeholder="{{ doctorSettings.preferred_payment_method }}"
                (selectionChange)="
                  onChangeEvent('preferred_payment_method', $event.value)
                "
              >
                <mat-option
                  *ngFor="
                    let payment_method of doctorSettings.supported_payment_methods
                  "
                  value="{{ payment_method }}"
                  >{{ payment_method | uppercase }}
                </mat-option>
              </mat-select>
              <mat-hint>{{
                "connect.doctor_settings.preferred_payment_method" | translate
              }}</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="kt-portlet__body border-top">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>
              {{ "connect.doctor_settings.instant_confirmation" | translate }}
            </h3>
            <p>
              {{
                "connect.doctor_settings.enable_confirmation_of_appointments"
                  | translate
              }}
            </p>
          </div>
          <div>
            <mat-slide-toggle [(ngModel)]="doctorSettings.instant_booking" (change)="onChangeEvent('instant_booking', $event)"></mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="kt-portlet__body border-top">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>
              {{
                "connect.doctor_settings.double_authentification" | translate
              }}
            </h3>
            <p>{{ "connect.doctor_settings.active_deactive" | translate }}</p>
          </div>
          <div>
            <mat-slide-toggle [(ngModel)]="doctorSettings.two_factor_auth" (change)="onChangeEvent('two_factor_auth', $event)"></mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="kt-portlet__body">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>
              {{ "connect.doctor_settings.sms_confirmation" | translate }}
            </h3>
            <p>
              {{ "connect.doctor_settings.send_an_automatic_sms" | translate }}
            </p>
          </div>
          <div>
            <mat-slide-toggle [(ngModel)]="doctorSettings.confirmation_sms" (change)="onChangeEvent('confirmation_sms', $event)"></mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="kt-portlet__body border-top">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>
              {{ "connect.doctor_settings.weekly_comments_email" | translate }}
            </h3>
            <p>
              {{
                "connect.doctor_settings.weekly_email_of_patient_comments"
                  | translate
              }}
            </p>
          </div>
          <div>
            <mat-slide-toggle [(ngModel)]="doctorSettings.weekly_reviews" (change)="onChangeEvent('weekly_reviews', $event)"></mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="kt-portlet__body border-top">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>
              {{
                "connect.doctor_settings.messenger_functionality" | translate
              }}
            </h3>
            <p>
              {{
                "connect.doctor_settings.users_visiting_your_profile"
                  | translate
              }}
            </p>
          </div>
          <div>
            <mat-slide-toggle [(ngModel)]="doctorSettings.messaging" (change)="onChangeEvent('messaging', $event)"></mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="kt-portlet__body border-top">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>
              {{ "connect.doctor_settings.hide_patient_comments" | translate }}
            </h3>
            <p>
              {{ "connect.doctor_settings.hide_your_patients" | translate }}
            </p>
          </div>
          <div>
            <mat-slide-toggle [(ngModel)]="doctorSettings.hide_reviews" (change)="onChangeEvent('hide_reviews', $event)"></mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="kt-portlet__body border-top">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>{{ "connect.doctor_settings.block_holidays" | translate }}</h3>
            <p>
              {{
                "connect.doctor_settings.automatically_block_holidays"
                  | translate
              }}
            </p>
          </div>
          <div>
            <mat-slide-toggle [(ngModel)]="doctorSettings.block_holidays" (change)="onChangeEvent('block_holidays', $event)"></mat-slide-toggle>
          </div>
        </div>
      </div>

      <div class="kt-portlet__body border-top">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>{{ "connect.doctor_settings.assistants" | translate }}</h3>
            <p>
              {{ "connect.doctor_settings.add_remove_assistants" | translate }}
            </p>
          </div>
          <div class="setting-bx">
            <button
              color="primary"
              (click)="navigateUrl('assistants')"
              class="btn btn-success btn-elevate btn-pill setting-btn"
            >
              {{ "connect.doctor_settings.assistants" | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="kt-portlet__body">
        <div class="static-wrapper">
          <div class="col setting-bx">
            <h3>
              {{ "connect.doctor_settings.specialties_order" | translate }}
            </h3>
            <p>{{ "connect.doctor_settings.drag_and_drop" | translate }}</p>
          </div>
          <div
            cdkDropList
            class="col specialities-list list"
            (cdkDropListDropped)="drop($event)"
          >
            <ng-container
              *ngFor="let speciality of specialities; let i = index"
            >
              <div class="speciality-box" cdkDrag id="{{ speciality.id }}">
                <b>{{ i + 1 }} . {{ speciality.name | uppercase }}</b>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="kt-portlet__body border-top">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>{{ "connect.doctor_settings.partners" | translate }}</h3>
            <p>
              {{
                "connect.doctor_settings.consult_the_list_of_partners"
                  | translate
              }}
            </p>
          </div>
          <div class="setting-bx">
            <a
              href="/partners"
              class="btn btn-success btn-elevate btn-pill setting-btn"
              >{{ "connect.doctor_settings.partners" | translate }}</a
            >
          </div>
        </div>
      </div>

      <div class="kt-portlet__body border-top">
        <div class="static-wrapper">
          <div class="setting-bx">
            <h3>
              {{
                "connect.doctor_settings.data_protection_charter" | translate
              }}
            </h3>
          </div>
          <div class="setting-bx">
            <a
              href="https://res.cloudinary.com/hh9gnin1v/image/upload/v1594209506/2007_-_DabaDoc_Charte_de_protection_des_donn%C3%A9es_zwgk8s.png"
              class="btn btn-success btn-elevate btn-pill setting-btn"
              target="_blank"
              download
              >{{ "connect.doctor_settings.download" | translate }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
